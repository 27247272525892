// Обнуление позиционирования
%clearfix {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

// Центрирование элемента
@mixin centered($axis:'both') {
    position: absolute;
    @if $axis=='x' {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $axis=='both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @if $axis=='y' {
        top: 50%;
        transform: translateY(-50%);
    }
}

@mixin luchMin($pos:'right') {
    font-size: 18px;
    &:after {
        content: "";
        position: absolute;
        @if $pos=='left' {
            left: -12px;
        }
        @if $pos=='right' {
            right: -15.5px;
        }
        margin-top: 7px;
        width: 4px;
        height: 4px;
        background: #4187a9;
        border-radius: 50%;
        border: 1px solid white;
        transition: transform .5s ease-in-out .5s;
    }
    &:before {
        content: "";
        position: absolute;
        @if $pos=='left' {
            left: -10px;
        }
        @if $pos=='right' {
            right: -13px;
        }
        margin-top: 11px;
        width: 1px;
        height: 0;
        background: linear-gradient(#a4defc, #42a4ce);
        box-shadow: 0 0 10px white;
        transition: height 1s ease-in-out .3s;
    }
}

@mixin luchMax($pos:'right') {
    font-size: 34px;
    transition: all .5s ease;
    &:after {
        content: "";
        position: absolute;
        @if $pos=='left' {
            left: -14px;
        }
        @if $pos=='right' {
            right: -15.5px;
        }
        margin-top: 15px;
        width: 4px;
        height: 4px;
        background: #4187a9;
        border-radius: 50%;
        border: 1px solid white;
        transition: transform .5s ease-in-out .5s;
    }
    &:before {
        content: "";
        position: absolute;
        @if $pos=='left' {
            left: -12px;
        }
        @if $pos=='right' {
            right: -13px;
        }
        margin-top: 18px;
        width: 1px;
        height: 0;
        background: linear-gradient(#a4defc, #42a4ce);
        box-shadow: 0 0 10px white;
        transition: height 1s ease-in-out .3s;
    }
}

// Адаптивность
@mixin desktop {
    @media (min-width: 1280px) and (max-width: 1440px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1441px) and (max-width: 1680px) {
        @content;
    }
}
@mixin retina {
    @media (min-width: 1681px) {
        @content;
    }
}

@mixin tablets {
    @media (min-width: 576px) and (max-width: 959px) {
        @content;
    }
}

@mixin phones {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin ratio {
    @media (min-aspect-ratio: 16 / 9) {
        @content;
    }
}

@mixin landscape {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin keyHeight($height) {
    @keyframes heightLine {
        0% {
            height: 0;
        }
        100% {
            height: $height;
        }
    }
}