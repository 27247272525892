/**
* @section map
* Секция карты
*/

/**
* @subsection titles
* Наименования городов
*/

.map {
    position: relative;
    width: 100%;
    height: 100vh;
    &__bg {
        @include centered(x);
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        & img {
            @include centered(x);
            bottom: 0;
            width: 1440px;
            @include desktop {
                width: 1680px;
            }
            @include retina {
                width: 1920px;
            }
        }
    }
    &Content {
        @include centered(x);
        z-index: 1;
        width: 100%;
        height: 100%;
        bottom: 0;
        &Items {
            // background:rgba(red,.2);
            bottom: 0;
            @include centered(x);
            height: 100vh;
            width: 1440px;
            @include desktop {
                width: 1680px;
            }
            @include retina {
                width: 1920px;
            }
            &__japan {
                position: absolute;
                z-index: 20;
                opacity: .8;
                cursor: pointer;
                &:hover {
                    opacity: 0;
                }
            }
            &__title {
                position: absolute;
                z-index: 5;
                font-family: "Roboto";
                font-size: 34px;
                line-height: 1;
                color: rgba(white, .8);
                text-align: center;
                opacity: 1;
                animation: textAni 4s linear;
            }
            &__mos {
                left: 8%;
                bottom: 460px;
                font-size: 34px;
                @include luchMax("left");
                @include desktop {
                    bottom: 532px;
                }
                @include retina {
                    bottom: 605px;
                }
            }
            &__nov {
                left: 12%;
                bottom: 515px;
                @include luchMin;
                @include desktop {
                    left: 13.5%;
                    bottom: 593px;
                }
                @include retina {
                    left: 14.5%;
                    bottom: 671px;
                }
            }
            &__seu {
                left: 403px;
                bottom: 240px;
                @include luchMax;
                @include desktop {
                    left: 484px;
                    bottom: 273px;
                }
                @include retina {
                    left: 565px;
                    bottom: 306px;
                }
            }
            &__tok {
                left: 670px;
                bottom: 235px;
                @include luchMax;
                @include desktop {
                    left: 799px;
                    bottom: 251px;
                }
                @include retina {
                    left: 928px;
                    bottom: 267px;
                }
            }
            &__vla {
                left: 37.5%;
                bottom: 430px;
                @include luchMin;
                @include desktop {
                    left: 38.7%;
                    bottom: 484px;
                }
                @include retina {
                    left: 39.6%;
                    bottom: 543px;
                }
            }
            &__hab {
                left: 44%;
                bottom: 520px;
                @include luchMin;
                @include desktop {
                    left: 45.1%;
                    bottom: 595px;
                }
                @include retina {
                    left: 46%;
                    bottom: 661px;
                }
            }
            &__vak {
                left: 777px;
                bottom: 424px;
                @include luchMin;
                @include desktop {
                    left: 921px;
                    bottom: 487px;
                }
                @include retina {
                    left: 1066px;
                    bottom: 549px;
                }
            }
            &__sap {
                left: 875px;
                bottom: 345px;
                @include luchMin("left");
                @include desktop {
                    left: 1019px;
                    bottom: 397px;
                }
                @include retina {
                    left: 1163px;
                    bottom: 454px;
                }
            }
            &__kor {
                left: 62.5%;
                bottom: 447px;
                @include luchMin("left");
                @include desktop {
                    left: 62.5%;
                    bottom: 515px;
                }
                @include retina {
                    left: 62.3%;
                    bottom: 580px;
                }
            }
            &__kun {
                left: 973px;
                bottom: 427px;
                @include luchMin("left");
                @include desktop {
                    left: 972px;
                    bottom: 477px;
                }
                @include retina {
                    left: 1295px;
                    bottom: 540px;
                }
            }
            &__itu {
                left: 1004px;
                bottom: 390px;
                @include luchMin("left");
                @include desktop {
                    left: 1168px;
                    bottom: 448px;
                }
                @include retina {
                    left: 1337px;
                    bottom: 508px;
                }
            }
            &__jus {
                left: 892px;
                bottom: 490px;
                @include luchMax("left");
                @include desktop {
                    left: 1043px;
                    bottom: 557px;
                }
                @include retina {
                    left: 1190px;
                    bottom: 626px;
                }
            }
        }
    }
}

/**
* @subsection titlesCustom
* Настройки анимации наименований городов
*/

.mapContentItems__seu {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 70px;
        animation: heightLine70 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__mos {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 60px;
        animation: heightLine60 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__nov {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 50px;
        animation: heightLine50 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__hab {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 100px;
        animation: heightLine100 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__vla {
    &:after {}
    &:before {
        height: 100px;
        animation: heightLine100 4s linear;
    }
}

.mapContentItems__itu {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 35px;
        animation: heightLine35 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__kun {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 90px;
        animation: heightLine90 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__jus {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 95px;
        animation: heightLine955 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__tok {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 155px;
        animation: heightLine155 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__kor {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 58px;
        animation: heightLine58 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__sap {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        height: 35px;
        animation: heightLine35 4s linear;
        transition: all 2s ease .5s;
    }
}

.mapContentItems__vak {
    &:after {
        transition: all .5s ease .5s;
    }
    &:before {
        animation: heightLine63 4s linear;
        height: 63px;
        transition: all 2s ease .5s;
    }
}

/**
* @subsection triggers
* Настройки областей-триггеров и анимации при наведении на них
*/

#koreaTrigger {
    position: absolute;
    z-index: 50;
    bottom: 84px;
    left: 29.4%;
    width: 10.5vw;
    height: 10.5vw;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    &:hover {
        &~.mapContentItems__mos{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__nov{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__hab{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__vla{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__kun{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__itu{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__tok{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__vak{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__sap{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__kor{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.pulseCircle__seu {
            opacity: .8;
        }
        &~#korea path {
            stroke-width: none;
            fill: url(#gradKorea);
            fill-opacity: .8;
            transition: fill-opacity .3s ease;
        }
        &~#seu-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~.plane__seu {
            opacity: 1;
            transition: opacity .3s ease-out 1.7s;
        }
    }
}

#sakhalinTrigger {
    position: absolute;
    z-index: 50;
    bottom: 330px;
    left: 58%;
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    
    @include desktop {
        bottom: 384px;
    }
    @include retina {
        bottom: 443px;
    }
    &~#sakhalin path {
        fill: #1f88b7;
        fill-opacity: .5;
        transition: all .8s ease;
    }
    &~#sakhalin #sp1 {
        fill: url(#sg1);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp2 {
        fill: url(#sg2);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp3 {
        fill: url(#sg3);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp4 {
        fill: url(#sg4);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp5 {
        fill: url(#sg5);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp6 {
        fill: url(#sg6);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp7 {
        fill: url(#sg7);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp8 {
        fill: url(#sg8);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &~#sakhalin #sp9 {
        fill: url(#sg9);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    &:hover {
        &~.mapContentItems__seu{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__tok{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__vak{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__sap{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.pulseCircle__jus {
            opacity: .8;
        }
        &~.pulseCircle__kun {
            opacity: .8;
        }
        &~.pulseCircle__itu {
            opacity: .8;
        }
        &~#sakhalin #sp1,
        &~#sakhalin #sp2,
        &~#sakhalin #sp3,
        &~#sakhalin #sp4,
        &~#sakhalin #sp5,
        &~#sakhalin #sp6,
        &~#sakhalin #sp7,
        &~#sakhalin #sp8,
        &~#sakhalin #sp9
        {
            fill: url(#sg1);
            fill-opacity: 1;
            transition: fill-opacity .3s ease;
        }
        // &~#sakhalin #sp2 {
        //     fill: url(#sg2);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp3 {
        //     fill: url(#sg3);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp4 {
        //     fill: url(#sg4);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp5 {
        //     fill: url(#sg5);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp6 {
        //     fill: url(#sg6);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp7 {
        //     fill: url(#sg7);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp8 {
        //     fill: url(#sg8);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#sakhalin #sp9 {
        //     fill: url(#sg9);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
    }
    &#sakhalin g {
        cursor: pointer;
    }
    &#sakhalin g path {
        // @include centered();
        stroke-width: 0;
        stroke: white;
    }
    &:hover {
        &#sakhalin g {
            stroke-width: none;
            fill-opacity: 1;
            transition: fill-opacity .3s ease;
        }
        &~#khb-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#msk-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#nov-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#vla-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#itu-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#kun-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~.plane__mos {
            opacity: 1;
            transition: opacity .3s ease-out 1.3s;
        }
        &~.plane__hab {
            opacity: 1;
            transition: opacity .3s ease-out 1.9s;
        }
        &~.plane__vla {
            opacity: 1;
            transition: opacity .3s ease-out 1.4s;
        }
        &~.plane__nov {
            opacity: 1;
            transition: opacity .3s ease-out 1.5s;
        }
        &~.plane__kun {
            opacity: 1;
            transition: opacity .3s ease-out 2.4s;
        }
        &~.plane__itu {
            opacity: 1;
            transition: opacity .3s ease-out 2.5s;
        }
    }
}

#japanTrigger {
    position: absolute;
    z-index: 50;
    bottom: 30px;
    left: 30.9%;
    width: 41.5%;
    height: 14.5vw;
    transform: rotate(-42deg);
    margin: auto;
    cursor: pointer;
    &:hover {
        &~.mapContentItems__mos{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__nov{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__hab{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__vla{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__kun{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__itu{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.mapContentItems__seu{
            transition:opacity .5s linear;
            opacity:0;
        }
        &~.pulseCircle__tok {
            opacity: .8;
        }
        &~.pulseCircle__sap {
            opacity: .8;
        }
        &~.pulseCircle__vak {
            opacity: .8;
        }
        &~#jap path{
            fill: url(#jg1);
            fill-opacity: 1;
            transition: fill-opacity .3s ease;
        }
        // &~#jap #jp2 {
        //     fill: url(#jg2);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#jap #jp3 {
        //     fill: url(#jg3);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#jap #jp4 {
        //     fill: url(#jg4);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#jap #jp5 {
        //     fill: url(#jg5);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#jap #jp6 {
        //     fill: url(#jg6);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        // &~#jap #jp7 {
        //     fill: url(#jg7);
        //     fill-opacity: 1;
        //     transition: fill-opacity .3s ease;
        // }
        &~#jap g {
            stroke-width: none;
            fill-opacity: 1;
            transition: fill-opacity .3s ease;
        }
        &~#tok-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#wak-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~#sap-ys {
            & path {
                stroke-dasharray: 12 5;
                stroke-dashoffset: 15;
            }
        }
        &~.plane__sap {
            opacity: 1;
            transition: opacity .3s ease-out 3.2s;
        }
        &~.plane__tok {
            opacity: 1;
            transition: opacity .3s ease-out 3.2s;
        }
        &~.plane__kor {
            opacity: 1;
            transition: opacity .3s ease-out 1.5s;
        }
    }
}

/**
* @subsection countrys
* Настройки SVG-областей Кореи,Сахалина и Японии
*/

#korea {
    position: absolute;
    z-index: 4;
    bottom: 84px;
    left: 30.4%;
    width: 8.5%;
    margin: auto;
    cursor: pointer;
    @include desktop {
        bottom: 111px;
    }
    @include retina {
        bottom: 139px;
    }
    & path {
        // animation: boxPulse 1s 1 2s;
    }
    & path {
        stroke-width: 0;
        stroke: white;
        fill: url(#gradKorea);
        fill: #2cafec;
        fill-opacity: .3;
        filter: url(#shadowKorea);
        transition: all .8s ease;
        animation: boxPulseKor 1s 1 2s;
        
    }
}

#sakhalin {
    position: absolute;
    z-index: 2;
    bottom: 93px;
    left: 48.9%;
    width: 36.5%;
    margin: auto;
    @include desktop {
        bottom: 163px;
    }
    @include retina {
        bottom: 234px;
    }
    & path {
        animation: boxPulseSah 1s linear;
    }
}

#jap {
    position: absolute;
    z-index: 3;
    bottom: -74px;
    left: 30.5%;
    width: 36.3%;
    @include desktop {
        bottom: -43px;
    }
    @include retina {
        bottom: -11px;
    }
    & path {
        animation: boxPulseJap 1s linear 1s;
    }
    & #jp1 {
        fill: url(#jg1);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp2 {
        fill: url(#jg2);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp3 {
        fill: url(#jg3);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp4 {
        fill: url(#jg4);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp5 {
        fill: url(#jg5);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp6 {
        fill: url(#jg6);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & #jp7 {
        fill: url(#jg7);
        fill-opacity: .1;
        fill: #2cafec;
        fill-opacity: .4;
        transition: fill-opacity .3s ease;
    }
    & path {
        fill: #1f88b7;
        fill-opacity: .5;
        transition: all .8s ease;
    }
    & g {
        cursor: pointer;
    }
    & g path {
        // @include centered();
        stroke-width: 0;
        stroke: white;
        transition: fill-opacity .3s ease;
    }
}

/**
* @subsection roads
* Настройки SVG-траекторий путей и их анимаций
*/

#khb-ys {
    position: absolute;
    z-index: 5;
    bottom: 375px;
    left: 51.2%;
    width: 10%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 500;
        transition: stroke-dasharray 2s ease-in-out;
    }
    @include desktop {
        bottom: 446px;
    }
    @include retina {
        bottom: 512px;
    }
}

#msk-ys {
    position: absolute;
    z-index: 5;
    bottom: 342px;
    left: 3.4%;
    width: 57.9%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 1000; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out;
    }
    @include desktop {
        bottom: 420px;
    }
    @include retina {
        bottom: 498px;
    }
}

#nov-ys {
    position: absolute;
    z-index: 5;
    bottom: 390px;
    left: 20.6%;
    width: 40.8%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 1000; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 1.6s ease-in-out .2s;
    }
    @include desktop {
        bottom: 467px;
    }
    @include retina {
        bottom: 545px;
    }
}

#vla-ys {
    position: absolute;
    z-index: 5;
    bottom: 322px;
    left: 46%;
    width: 15%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 1000; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out;
    }
    @include desktop {
        bottom: 384px;
    }
    @include retina {
        bottom: 446px;
    }
}

#itu-ys {
    position: absolute;
    z-index: 5;
    bottom: 352px;
    left: 61%;
    width: 8%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 500; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out .5s;
    }
    @include desktop {
        bottom: 415px;
    }
    @include retina {
        bottom: 480px;
    }
}

#kun-ys {
    position: absolute;
    z-index: 5;
    bottom: 321px;
    left: 61.9%;
    width: 5%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 500; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out .5s;
    }
    @include desktop {
        bottom: 393px;
    }
    @include retina {
        bottom: 459px;
    }
}

#tok-ys {
    position: absolute;
    z-index: 5;
    bottom: 38px;
    left: 50.8%;
    width: 10.2%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 700;
        transition: stroke-dasharray 2s ease-in-out 1.5s;
    }
    @include desktop {
        bottom: 82px;
    }
    @include retina {
        bottom: 123px;
    }
}

#wak-ys {
    position: absolute;
    z-index: 5;
    bottom: 367px;
    left: 60.3%;
    width: 1.6%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 100;
        transition: stroke-dasharray 1.5s ease-in-out;
    }
    @include desktop {
        bottom: 432px;
    }
    @include retina {
        bottom: 495px;
    }
}

#sap-ys {
    position: absolute;
    z-index: 5;
    bottom: 308px;
    left: 60.1%;
    width: 2.4%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 500; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out 1.5s;
    }
    @include desktop {
        bottom: 367px;
    }
    @include retina {
        bottom: 427px;
    }
}

#seu-ys {
    position: absolute;
    z-index: 5;
    bottom: 147px;
    left: 34.2%;
    width: 27.1%;
    cursor: pointer;
    & path {
        stroke-dashoffset: 0;
        stroke-dasharray: 0 1000; // stroke-dasharray: 12 5;
        transition: stroke-dasharray 2s ease-in-out;
    }
    @include desktop {
        bottom: 200px;
    }
    @include retina {
        bottom: 251px;
    }
}

/**
* @subsection planes
* Настройки иконок транспорта
*/

.plane {
    position: absolute;
    z-index: 15;
    opacity: 0;
    &__seu {
        left: 45%;
        bottom: 184px;
        transform: rotate(-103deg);
        opacity: 0;
        @include desktop {
            bottom: 220px;
        }
        @include retina {
            bottom: 255px;
        }
    }
    &__tok {
        left: 51.6%;
        bottom: 284px;
        transform: rotate(-156deg);
        opacity: 0;
        @include desktop {
            bottom: 332px;
        }
        @include retina {
            bottom: 374px;
        }
    }
    &__kor {
        left: 61%;
        bottom: 374px;
        opacity: 0;
        @include desktop {
            bottom: 437px;
        }
        @include retina {
            bottom: 499px;
        }
    }
    &__sap {
        left: 60.3%;
        bottom: 319px;
        transform: rotate(-140deg);
        opacity: 0;
        @include desktop {
            bottom: 371px;
        }
        @include retina {
            bottom: 428px;
        }
    }
    &__mos {
        left: 14%;
        bottom: 453px;
        transform: rotate(65deg);
        opacity: 0;
        @include desktop {
            bottom: 529px;
        }
        @include retina {
            bottom: 606px;
        }
    }
    &__nov {
        left: 35%;
        bottom: 497px;
        transform: rotate(95deg);
        opacity: 0;
        @include desktop {
            bottom: 583px;
        }
        @include retina {
            bottom: 668px;
        }
    }
    &__hab {
        left: 55.6%;
        bottom: 369px;
        transform: rotate(90deg);
        opacity: 0;
        @include desktop {
            bottom: 436px;
        }
        @include retina {
            bottom: 500px;
        }
    }
    &__vla {
        left: 50.3%;
        bottom: 323px;
        transform: rotate(90deg);
        opacity: 0;
        @include desktop {
            bottom: 378px;
        }
        @include retina {
            bottom: 436px;
        }
    }
    &__kun {
        left: 64.4%;
        bottom: 362px;
        opacity: 0;
        @include desktop {
            bottom: 433px;
        }
        @include retina {
            bottom: 500px;
        }
    }
    &__itu {
        left: 64.4%;
        bottom: 395px;
        transform: rotate(112deg);
        opacity: 0;
        @include desktop {
            bottom: 462px;
        }
        @include retina {
            bottom: 531px;
        }
    }
}

/**
* @subsection circles
* Настройки кружков городов
*/

.pulseCircle {
    position: absolute;
    z-index: 30;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(black, .6);
    opacity: 0; // box-shadow: 0 0 5px transparent;
    transition: opacity 1s ease .5s;
    animation: citys 6s linear;
    &:after {
        content: "";
        @include centered();
        z-index: 30;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        border: 1px solid white;
        animation: pulse 1s linear infinite;
    }
    &__seu {
        bottom: 182px;
        left: 33.7%;
        @include desktop {
            bottom: 215px;
        }
        @include retina {
            bottom: 248px;
        }
    }
    &__tok {
        bottom: 90px;
        left: 53.7%;
        @include desktop {
            bottom: 108px;
        }
        @include retina {
            bottom: 123px;
        }
    }
    &__sap {
        bottom: 312px;
        left: 59.8%;
        @include desktop {
            bottom: 363px;
        }
        @include retina {
            bottom: 417px;
        }
    }
    &__vak {
        bottom: 363px;
        left: 60.1%;
        @include desktop {
            bottom: 428px;
        }
        @include retina {
            bottom: 489px;
        }
    }
    &__jus {
        bottom: 404px;
        left: 60.8%;
        @include desktop {
            bottom: 474px;
            left: 61.1%;
        }
        @include retina {
            bottom: 541px;
            left: 61.1%;
        }
    }
    &__itu {
        bottom: 356px;
        left: 68.7%;
        @include desktop {
            bottom: 414px;
        }
        @include retina {
            left: 68.9%;
            bottom: 474px;
        }
    }
    &__kun {
        bottom: 339px;
        left: 66.5%;
        @include desktop {
            bottom: 396px;
        }
        @include retina {
            left: 66.7%;
            bottom: 454px;
        }
    }
}

/**
* @subsection animations
* Настройки кейфреймов анимаций
*/

@keyframes pulse {
    0% {
        width: 5px;
        height: 5px;
        border: 1px solid white;
    }
    90% {
        width: 25px;
        height: 25px;
        border: 1px solid white;
    }
    100% {
        width: 25px;
        height: 25px;
        border: 1px solid white;
    }
}

// @keyframes boxPulse {
//     0% {
//         fill: rgba(#2cafec, .4);
//     }
//     40% {
//         fill: rgb(250,2,60);
//     }
//     70% {
//         fill: rgb(250,2,60);
//     }
//     100% {
//         fill: rgba(#2cafec, .4);
//     }
// }
@keyframes boxPulseKor {
    0% {
        fill: rgba(#2cafec, .8);
    }
    40% {
        fill: url(#gradKorea);
        fill-opacity: .8;
    }
    70% {
        fill: url(#gradKorea);
        fill-opacity: .8;
    }
    100% {
        fill: rgba(#2cafec, .8);
    }
}
@keyframes boxPulseJap {
    0% {
        fill: rgba(#2cafec, .8);
    }
    40% {
        fill: url(#jg1);
        fill-opacity: .8;
    }
    70% {
        fill: url(#jg1);
        fill-opacity: .8;
    }
    100% {
        fill: rgba(#2cafec, .8);
    }
}
@keyframes boxPulseSah {
    0% {
        fill: rgba(#2cafec, .8);
    }
    40% {
        fill: url(#sg1);
        fill-opacity: .8;
    }
    70% {
        fill: url(#sg1);
        fill-opacity: .8;
    }
    100% {
        fill: rgba(#2cafec, .8);
    }
}
@keyframes heightLine100 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 100px;
    }
}

@keyframes heightLine35 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 35px;
    }
}

@keyframes heightLine50 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 50px;
    }
}
@keyframes heightLine70 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 70px;
    }
}

@keyframes heightLine63 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 63px;
    }
}

@keyframes heightLine58 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 58px;
    }
}

@keyframes heightLine90 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 90px;
    }
}

@keyframes heightLine130 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 130px;
    }
}

@keyframes heightLine60 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 60px;
    }
}

@keyframes heightLine95 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 95px;
    }
}

@keyframes heightLine155 {
    0% {
        height: 0;
    }
    65% {
        height: 0;
    }
    100% {
        height: 155px;
    }
}

@keyframes textAni {
    0% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes citys {
    0% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}