/**
* @package map
* @version 1.0
* @author Katser Siarhei <admin@webskar.ru>
*/
/**
********** enumeration of sections **********
1.Base - Секция базовых настроек
2.Map - Секция карты
2.1.titles - Наименования городов
2.2.titlesCustom - Настройки анимации наименований городов
2.3.triggers - Настройки областей-триггеров и анимации при наведении на них
2.4.countrys - Настройки SVG-областей Кореи,Сахалина и Японии
2.5.roads - Настройки SVG-траекторий путей и их анимаций
2.6.planes - Настройки иконок транспорта
2.7.circles - Настройки кружков городов
2.8.animations - Настройки кейфреймов анимаций
3. Other - Стили вне секции Map
*/
@import "mixins/mixins.scss";
@import "common/base.scss";
@import "common/fonts.scss";
// @import "common/common.scss";
@import "components/map.scss";

.sectionTitle{
    @include centered();
    font-family: "Roboto";
    font-size: 60px;
    color: white;
    text-align: center;
}
.japan{
    position: relative;
    z-index: 20;
    width: 100%;
    height: 100vh;
    background: rgb(190, 89, 89);
    overflow: hidden;
}
.korea{
    position: relative;
    width: 100%;
    height: 100vh;
    background: rgb(202, 200, 88);
}
.sakhalin{
    position: relative;
    width: 100%;
    height: 50vh;
    background: rgb(147, 94, 182);
}