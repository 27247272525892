@charset "UTF-8";
/**
* @package map
* @version 1.0
* @author Katser Siarhei <admin@webskar.ru>
*/
/**
********** enumeration of sections **********
1.Base - Секция базовых настроек
2.Map - Секция карты
2.1.titles - Наименования городов
2.2.titlesCustom - Настройки анимации наименований городов
2.3.triggers - Настройки областей-триггеров и анимации при наведении на них
2.4.countrys - Настройки SVG-областей Кореи,Сахалина и Японии
2.5.roads - Настройки SVG-траекторий путей и их анимаций
2.6.planes - Настройки иконок транспорта
2.7.circles - Настройки кружков городов
2.8.animations - Настройки кейфреймов анимаций
3. Other - Стили вне секции Map
*/
/**
* @section base
* Базовые настройки страницы
*/
@import url("https://fonts.googleapis.com/css?family=Roboto");
body,
html {
  margin: 0;
  height: 100%; }

body {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42;
  -webkit-font-smoothing: antialaised; }

.wrapper {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

/**
* @section map
* Секция карты
*/
/**
* @subsection titles
* Наименования городов
*/
.map {
  position: relative;
  width: 100%;
  height: 100vh; }
  .map__bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%; }
    .map__bg img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 1440px; }
      @media (min-width: 1441px) and (max-width: 1680px) {
        .map__bg img {
          width: 1680px; } }
      @media (min-width: 1681px) {
        .map__bg img {
          width: 1920px; } }
  .mapContent {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
    bottom: 0; }
    .mapContentItems {
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100vh;
      width: 1440px; }
      @media (min-width: 1441px) and (max-width: 1680px) {
        .mapContentItems {
          width: 1680px; } }
      @media (min-width: 1681px) {
        .mapContentItems {
          width: 1920px; } }
      .mapContentItems__japan {
        position: absolute;
        z-index: 20;
        opacity: .8;
        cursor: pointer; }
        .mapContentItems__japan:hover {
          opacity: 0; }
      .mapContentItems__title {
        position: absolute;
        z-index: 5;
        font-family: "Roboto";
        font-size: 34px;
        line-height: 1;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        opacity: 1;
        animation: textAni 4s linear; }
      .mapContentItems__mos {
        left: 8%;
        bottom: 460px;
        font-size: 34px;
        font-size: 34px;
        transition: all .5s ease; }
        .mapContentItems__mos:after {
          content: "";
          position: absolute;
          left: -14px;
          margin-top: 15px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__mos:before {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 18px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__mos {
            bottom: 532px; } }
        @media (min-width: 1681px) {
          .mapContentItems__mos {
            bottom: 605px; } }
      .mapContentItems__nov {
        left: 12%;
        bottom: 515px;
        font-size: 18px; }
        .mapContentItems__nov:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__nov:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__nov {
            left: 13.5%;
            bottom: 593px; } }
        @media (min-width: 1681px) {
          .mapContentItems__nov {
            left: 14.5%;
            bottom: 671px; } }
      .mapContentItems__seu {
        left: 403px;
        bottom: 240px;
        font-size: 34px;
        transition: all .5s ease; }
        .mapContentItems__seu:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 15px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__seu:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 18px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__seu {
            left: 484px;
            bottom: 273px; } }
        @media (min-width: 1681px) {
          .mapContentItems__seu {
            left: 565px;
            bottom: 306px; } }
      .mapContentItems__tok {
        left: 670px;
        bottom: 235px;
        font-size: 34px;
        transition: all .5s ease; }
        .mapContentItems__tok:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 15px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__tok:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 18px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__tok {
            left: 799px;
            bottom: 251px; } }
        @media (min-width: 1681px) {
          .mapContentItems__tok {
            left: 928px;
            bottom: 267px; } }
      .mapContentItems__vla {
        left: 37.5%;
        bottom: 430px;
        font-size: 18px; }
        .mapContentItems__vla:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__vla:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__vla {
            left: 38.7%;
            bottom: 484px; } }
        @media (min-width: 1681px) {
          .mapContentItems__vla {
            left: 39.6%;
            bottom: 543px; } }
      .mapContentItems__hab {
        left: 44%;
        bottom: 520px;
        font-size: 18px; }
        .mapContentItems__hab:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__hab:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__hab {
            left: 45.1%;
            bottom: 595px; } }
        @media (min-width: 1681px) {
          .mapContentItems__hab {
            left: 46%;
            bottom: 661px; } }
      .mapContentItems__vak {
        left: 777px;
        bottom: 424px;
        font-size: 18px; }
        .mapContentItems__vak:after {
          content: "";
          position: absolute;
          right: -15.5px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__vak:before {
          content: "";
          position: absolute;
          right: -13px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__vak {
            left: 921px;
            bottom: 487px; } }
        @media (min-width: 1681px) {
          .mapContentItems__vak {
            left: 1066px;
            bottom: 549px; } }
      .mapContentItems__sap {
        left: 875px;
        bottom: 345px;
        font-size: 18px; }
        .mapContentItems__sap:after {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__sap:before {
          content: "";
          position: absolute;
          left: -10px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__sap {
            left: 1019px;
            bottom: 397px; } }
        @media (min-width: 1681px) {
          .mapContentItems__sap {
            left: 1163px;
            bottom: 454px; } }
      .mapContentItems__kor {
        left: 62.5%;
        bottom: 447px;
        font-size: 18px; }
        .mapContentItems__kor:after {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__kor:before {
          content: "";
          position: absolute;
          left: -10px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__kor {
            left: 62.5%;
            bottom: 515px; } }
        @media (min-width: 1681px) {
          .mapContentItems__kor {
            left: 62.3%;
            bottom: 580px; } }
      .mapContentItems__kun {
        left: 973px;
        bottom: 427px;
        font-size: 18px; }
        .mapContentItems__kun:after {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__kun:before {
          content: "";
          position: absolute;
          left: -10px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__kun {
            left: 972px;
            bottom: 477px; } }
        @media (min-width: 1681px) {
          .mapContentItems__kun {
            left: 1295px;
            bottom: 540px; } }
      .mapContentItems__itu {
        left: 1004px;
        bottom: 390px;
        font-size: 18px; }
        .mapContentItems__itu:after {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 7px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__itu:before {
          content: "";
          position: absolute;
          left: -10px;
          margin-top: 11px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__itu {
            left: 1168px;
            bottom: 448px; } }
        @media (min-width: 1681px) {
          .mapContentItems__itu {
            left: 1337px;
            bottom: 508px; } }
      .mapContentItems__jus {
        left: 892px;
        bottom: 490px;
        font-size: 34px;
        transition: all .5s ease; }
        .mapContentItems__jus:after {
          content: "";
          position: absolute;
          left: -14px;
          margin-top: 15px;
          width: 4px;
          height: 4px;
          background: #4187a9;
          border-radius: 50%;
          border: 1px solid white;
          transition: transform .5s ease-in-out .5s; }
        .mapContentItems__jus:before {
          content: "";
          position: absolute;
          left: -12px;
          margin-top: 18px;
          width: 1px;
          height: 0;
          background: linear-gradient(#a4defc, #42a4ce);
          box-shadow: 0 0 10px white;
          transition: height 1s ease-in-out .3s; }
        @media (min-width: 1441px) and (max-width: 1680px) {
          .mapContentItems__jus {
            left: 1043px;
            bottom: 557px; } }
        @media (min-width: 1681px) {
          .mapContentItems__jus {
            left: 1190px;
            bottom: 626px; } }

/**
* @subsection titlesCustom
* Настройки анимации наименований городов
*/
.mapContentItems__seu:after {
  transition: all .5s ease .5s; }

.mapContentItems__seu:before {
  height: 70px;
  animation: heightLine70 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__mos:after {
  transition: all .5s ease .5s; }

.mapContentItems__mos:before {
  height: 60px;
  animation: heightLine60 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__nov:after {
  transition: all .5s ease .5s; }

.mapContentItems__nov:before {
  height: 50px;
  animation: heightLine50 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__hab:after {
  transition: all .5s ease .5s; }

.mapContentItems__hab:before {
  height: 100px;
  animation: heightLine100 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__vla:before {
  height: 100px;
  animation: heightLine100 4s linear; }

.mapContentItems__itu:after {
  transition: all .5s ease .5s; }

.mapContentItems__itu:before {
  height: 35px;
  animation: heightLine35 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__kun:after {
  transition: all .5s ease .5s; }

.mapContentItems__kun:before {
  height: 90px;
  animation: heightLine90 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__jus:after {
  transition: all .5s ease .5s; }

.mapContentItems__jus:before {
  height: 95px;
  animation: heightLine955 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__tok:after {
  transition: all .5s ease .5s; }

.mapContentItems__tok:before {
  height: 155px;
  animation: heightLine155 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__kor:after {
  transition: all .5s ease .5s; }

.mapContentItems__kor:before {
  height: 58px;
  animation: heightLine58 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__sap:after {
  transition: all .5s ease .5s; }

.mapContentItems__sap:before {
  height: 35px;
  animation: heightLine35 4s linear;
  transition: all 2s ease .5s; }

.mapContentItems__vak:after {
  transition: all .5s ease .5s; }

.mapContentItems__vak:before {
  animation: heightLine63 4s linear;
  height: 63px;
  transition: all 2s ease .5s; }

/**
* @subsection triggers
* Настройки областей-триггеров и анимации при наведении на них
*/
#koreaTrigger {
  position: absolute;
  z-index: 50;
  bottom: 84px;
  left: 29.4%;
  width: 10.5vw;
  height: 10.5vw;
  border-radius: 50%;
  margin: auto;
  cursor: pointer; }
  #koreaTrigger:hover ~ .mapContentItems__mos {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__nov {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__hab {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__vla {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__kun {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__itu {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__tok {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__vak {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__sap {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .mapContentItems__kor {
    transition: opacity .5s linear;
    opacity: 0; }
  #koreaTrigger:hover ~ .pulseCircle__seu {
    opacity: .8; }
  #koreaTrigger:hover ~ #korea path {
    stroke-width: none;
    fill: url(#gradKorea);
    fill-opacity: .8;
    transition: fill-opacity .3s ease; }
  #koreaTrigger:hover ~ #seu-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #koreaTrigger:hover ~ .plane__seu {
    opacity: 1;
    transition: opacity .3s ease-out 1.7s; }

#sakhalinTrigger {
  position: absolute;
  z-index: 50;
  bottom: 330px;
  left: 58%;
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  margin: auto;
  cursor: pointer; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #sakhalinTrigger {
      bottom: 384px; } }
  @media (min-width: 1681px) {
    #sakhalinTrigger {
      bottom: 443px; } }
  #sakhalinTrigger ~ #sakhalin path {
    fill: #1f88b7;
    fill-opacity: .5;
    transition: all .8s ease; }
  #sakhalinTrigger ~ #sakhalin #sp1 {
    fill: url(#sg1);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp2 {
    fill: url(#sg2);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp3 {
    fill: url(#sg3);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp4 {
    fill: url(#sg4);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp5 {
    fill: url(#sg5);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp6 {
    fill: url(#sg6);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp7 {
    fill: url(#sg7);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp8 {
    fill: url(#sg8);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger ~ #sakhalin #sp9 {
    fill: url(#sg9);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger:hover ~ .mapContentItems__seu {
    transition: opacity .5s linear;
    opacity: 0; }
  #sakhalinTrigger:hover ~ .mapContentItems__tok {
    transition: opacity .5s linear;
    opacity: 0; }
  #sakhalinTrigger:hover ~ .mapContentItems__vak {
    transition: opacity .5s linear;
    opacity: 0; }
  #sakhalinTrigger:hover ~ .mapContentItems__sap {
    transition: opacity .5s linear;
    opacity: 0; }
  #sakhalinTrigger:hover ~ .pulseCircle__jus {
    opacity: .8; }
  #sakhalinTrigger:hover ~ .pulseCircle__kun {
    opacity: .8; }
  #sakhalinTrigger:hover ~ .pulseCircle__itu {
    opacity: .8; }
  #sakhalinTrigger:hover ~ #sakhalin #sp1,
  #sakhalinTrigger:hover ~ #sakhalin #sp2,
  #sakhalinTrigger:hover ~ #sakhalin #sp3,
  #sakhalinTrigger:hover ~ #sakhalin #sp4,
  #sakhalinTrigger:hover ~ #sakhalin #sp5,
  #sakhalinTrigger:hover ~ #sakhalin #sp6,
  #sakhalinTrigger:hover ~ #sakhalin #sp7,
  #sakhalinTrigger:hover ~ #sakhalin #sp8,
  #sakhalinTrigger:hover ~ #sakhalin #sp9 {
    fill: url(#sg1);
    fill-opacity: 1;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger#sakhalin g {
    cursor: pointer; }
  #sakhalinTrigger#sakhalin g path {
    stroke-width: 0;
    stroke: white; }
  #sakhalinTrigger:hover#sakhalin g {
    stroke-width: none;
    fill-opacity: 1;
    transition: fill-opacity .3s ease; }
  #sakhalinTrigger:hover ~ #khb-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ #msk-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ #nov-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ #vla-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ #itu-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ #kun-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #sakhalinTrigger:hover ~ .plane__mos {
    opacity: 1;
    transition: opacity .3s ease-out 1.3s; }
  #sakhalinTrigger:hover ~ .plane__hab {
    opacity: 1;
    transition: opacity .3s ease-out 1.9s; }
  #sakhalinTrigger:hover ~ .plane__vla {
    opacity: 1;
    transition: opacity .3s ease-out 1.4s; }
  #sakhalinTrigger:hover ~ .plane__nov {
    opacity: 1;
    transition: opacity .3s ease-out 1.5s; }
  #sakhalinTrigger:hover ~ .plane__kun {
    opacity: 1;
    transition: opacity .3s ease-out 2.4s; }
  #sakhalinTrigger:hover ~ .plane__itu {
    opacity: 1;
    transition: opacity .3s ease-out 2.5s; }

#japanTrigger {
  position: absolute;
  z-index: 50;
  bottom: 30px;
  left: 30.9%;
  width: 41.5%;
  height: 14.5vw;
  transform: rotate(-42deg);
  margin: auto;
  cursor: pointer; }
  #japanTrigger:hover ~ .mapContentItems__mos {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__nov {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__hab {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__vla {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__kun {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__itu {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .mapContentItems__seu {
    transition: opacity .5s linear;
    opacity: 0; }
  #japanTrigger:hover ~ .pulseCircle__tok {
    opacity: .8; }
  #japanTrigger:hover ~ .pulseCircle__sap {
    opacity: .8; }
  #japanTrigger:hover ~ .pulseCircle__vak {
    opacity: .8; }
  #japanTrigger:hover ~ #jap path {
    fill: url(#jg1);
    fill-opacity: 1;
    transition: fill-opacity .3s ease; }
  #japanTrigger:hover ~ #jap g {
    stroke-width: none;
    fill-opacity: 1;
    transition: fill-opacity .3s ease; }
  #japanTrigger:hover ~ #tok-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #japanTrigger:hover ~ #wak-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #japanTrigger:hover ~ #sap-ys path {
    stroke-dasharray: 12 5;
    stroke-dashoffset: 15; }
  #japanTrigger:hover ~ .plane__sap {
    opacity: 1;
    transition: opacity .3s ease-out 3.2s; }
  #japanTrigger:hover ~ .plane__tok {
    opacity: 1;
    transition: opacity .3s ease-out 3.2s; }
  #japanTrigger:hover ~ .plane__kor {
    opacity: 1;
    transition: opacity .3s ease-out 1.5s; }

/**
* @subsection countrys
* Настройки SVG-областей Кореи,Сахалина и Японии
*/
#korea {
  position: absolute;
  z-index: 4;
  bottom: 84px;
  left: 30.4%;
  width: 8.5%;
  margin: auto;
  cursor: pointer; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #korea {
      bottom: 111px; } }
  @media (min-width: 1681px) {
    #korea {
      bottom: 139px; } }
  #korea path {
    stroke-width: 0;
    stroke: white;
    fill: url(#gradKorea);
    fill: #2cafec;
    fill-opacity: .3;
    filter: url(#shadowKorea);
    transition: all .8s ease;
    animation: boxPulseKor 1s 1 2s; }

#sakhalin {
  position: absolute;
  z-index: 2;
  bottom: 93px;
  left: 48.9%;
  width: 36.5%;
  margin: auto; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #sakhalin {
      bottom: 163px; } }
  @media (min-width: 1681px) {
    #sakhalin {
      bottom: 234px; } }
  #sakhalin path {
    animation: boxPulseSah 1s linear; }

#jap {
  position: absolute;
  z-index: 3;
  bottom: -74px;
  left: 30.5%;
  width: 36.3%; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #jap {
      bottom: -43px; } }
  @media (min-width: 1681px) {
    #jap {
      bottom: -11px; } }
  #jap path {
    animation: boxPulseJap 1s linear 1s; }
  #jap #jp1 {
    fill: url(#jg1);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp2 {
    fill: url(#jg2);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp3 {
    fill: url(#jg3);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp4 {
    fill: url(#jg4);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp5 {
    fill: url(#jg5);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp6 {
    fill: url(#jg6);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap #jp7 {
    fill: url(#jg7);
    fill-opacity: .1;
    fill: #2cafec;
    fill-opacity: .4;
    transition: fill-opacity .3s ease; }
  #jap path {
    fill: #1f88b7;
    fill-opacity: .5;
    transition: all .8s ease; }
  #jap g {
    cursor: pointer; }
  #jap g path {
    stroke-width: 0;
    stroke: white;
    transition: fill-opacity .3s ease; }

/**
* @subsection roads
* Настройки SVG-траекторий путей и их анимаций
*/
#khb-ys {
  position: absolute;
  z-index: 5;
  bottom: 375px;
  left: 51.2%;
  width: 10%;
  cursor: pointer; }
  #khb-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 500;
    transition: stroke-dasharray 2s ease-in-out; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #khb-ys {
      bottom: 446px; } }
  @media (min-width: 1681px) {
    #khb-ys {
      bottom: 512px; } }

#msk-ys {
  position: absolute;
  z-index: 5;
  bottom: 342px;
  left: 3.4%;
  width: 57.9%;
  cursor: pointer; }
  #msk-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 1000;
    transition: stroke-dasharray 2s ease-in-out; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #msk-ys {
      bottom: 420px; } }
  @media (min-width: 1681px) {
    #msk-ys {
      bottom: 498px; } }

#nov-ys {
  position: absolute;
  z-index: 5;
  bottom: 390px;
  left: 20.6%;
  width: 40.8%;
  cursor: pointer; }
  #nov-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 1000;
    transition: stroke-dasharray 1.6s ease-in-out .2s; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #nov-ys {
      bottom: 467px; } }
  @media (min-width: 1681px) {
    #nov-ys {
      bottom: 545px; } }

#vla-ys {
  position: absolute;
  z-index: 5;
  bottom: 322px;
  left: 46%;
  width: 15%;
  cursor: pointer; }
  #vla-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 1000;
    transition: stroke-dasharray 2s ease-in-out; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #vla-ys {
      bottom: 384px; } }
  @media (min-width: 1681px) {
    #vla-ys {
      bottom: 446px; } }

#itu-ys {
  position: absolute;
  z-index: 5;
  bottom: 352px;
  left: 61%;
  width: 8%;
  cursor: pointer; }
  #itu-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 500;
    transition: stroke-dasharray 2s ease-in-out .5s; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #itu-ys {
      bottom: 415px; } }
  @media (min-width: 1681px) {
    #itu-ys {
      bottom: 480px; } }

#kun-ys {
  position: absolute;
  z-index: 5;
  bottom: 321px;
  left: 61.9%;
  width: 5%;
  cursor: pointer; }
  #kun-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 500;
    transition: stroke-dasharray 2s ease-in-out .5s; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #kun-ys {
      bottom: 393px; } }
  @media (min-width: 1681px) {
    #kun-ys {
      bottom: 459px; } }

#tok-ys {
  position: absolute;
  z-index: 5;
  bottom: 38px;
  left: 50.8%;
  width: 10.2%;
  cursor: pointer; }
  #tok-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 700;
    transition: stroke-dasharray 2s ease-in-out 1.5s; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #tok-ys {
      bottom: 82px; } }
  @media (min-width: 1681px) {
    #tok-ys {
      bottom: 123px; } }

#wak-ys {
  position: absolute;
  z-index: 5;
  bottom: 367px;
  left: 60.3%;
  width: 1.6%;
  cursor: pointer; }
  #wak-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 100;
    transition: stroke-dasharray 1.5s ease-in-out; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #wak-ys {
      bottom: 432px; } }
  @media (min-width: 1681px) {
    #wak-ys {
      bottom: 495px; } }

#sap-ys {
  position: absolute;
  z-index: 5;
  bottom: 308px;
  left: 60.1%;
  width: 2.4%;
  cursor: pointer; }
  #sap-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 500;
    transition: stroke-dasharray 2s ease-in-out 1.5s; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #sap-ys {
      bottom: 367px; } }
  @media (min-width: 1681px) {
    #sap-ys {
      bottom: 427px; } }

#seu-ys {
  position: absolute;
  z-index: 5;
  bottom: 147px;
  left: 34.2%;
  width: 27.1%;
  cursor: pointer; }
  #seu-ys path {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 1000;
    transition: stroke-dasharray 2s ease-in-out; }
  @media (min-width: 1441px) and (max-width: 1680px) {
    #seu-ys {
      bottom: 200px; } }
  @media (min-width: 1681px) {
    #seu-ys {
      bottom: 251px; } }

/**
* @subsection planes
* Настройки иконок транспорта
*/
.plane {
  position: absolute;
  z-index: 15;
  opacity: 0; }
  .plane__seu {
    left: 45%;
    bottom: 184px;
    transform: rotate(-103deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__seu {
        bottom: 220px; } }
    @media (min-width: 1681px) {
      .plane__seu {
        bottom: 255px; } }
  .plane__tok {
    left: 51.6%;
    bottom: 284px;
    transform: rotate(-156deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__tok {
        bottom: 332px; } }
    @media (min-width: 1681px) {
      .plane__tok {
        bottom: 374px; } }
  .plane__kor {
    left: 61%;
    bottom: 374px;
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__kor {
        bottom: 437px; } }
    @media (min-width: 1681px) {
      .plane__kor {
        bottom: 499px; } }
  .plane__sap {
    left: 60.3%;
    bottom: 319px;
    transform: rotate(-140deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__sap {
        bottom: 371px; } }
    @media (min-width: 1681px) {
      .plane__sap {
        bottom: 428px; } }
  .plane__mos {
    left: 14%;
    bottom: 453px;
    transform: rotate(65deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__mos {
        bottom: 529px; } }
    @media (min-width: 1681px) {
      .plane__mos {
        bottom: 606px; } }
  .plane__nov {
    left: 35%;
    bottom: 497px;
    transform: rotate(95deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__nov {
        bottom: 583px; } }
    @media (min-width: 1681px) {
      .plane__nov {
        bottom: 668px; } }
  .plane__hab {
    left: 55.6%;
    bottom: 369px;
    transform: rotate(90deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__hab {
        bottom: 436px; } }
    @media (min-width: 1681px) {
      .plane__hab {
        bottom: 500px; } }
  .plane__vla {
    left: 50.3%;
    bottom: 323px;
    transform: rotate(90deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__vla {
        bottom: 378px; } }
    @media (min-width: 1681px) {
      .plane__vla {
        bottom: 436px; } }
  .plane__kun {
    left: 64.4%;
    bottom: 362px;
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__kun {
        bottom: 433px; } }
    @media (min-width: 1681px) {
      .plane__kun {
        bottom: 500px; } }
  .plane__itu {
    left: 64.4%;
    bottom: 395px;
    transform: rotate(112deg);
    opacity: 0; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .plane__itu {
        bottom: 462px; } }
    @media (min-width: 1681px) {
      .plane__itu {
        bottom: 531px; } }

/**
* @subsection circles
* Настройки кружков городов
*/
.pulseCircle {
  position: absolute;
  z-index: 30;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 1s ease .5s;
  animation: citys 6s linear; }
  .pulseCircle:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid white;
    animation: pulse 1s linear infinite; }
  .pulseCircle__seu {
    bottom: 182px;
    left: 33.7%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__seu {
        bottom: 215px; } }
    @media (min-width: 1681px) {
      .pulseCircle__seu {
        bottom: 248px; } }
  .pulseCircle__tok {
    bottom: 90px;
    left: 53.7%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__tok {
        bottom: 108px; } }
    @media (min-width: 1681px) {
      .pulseCircle__tok {
        bottom: 123px; } }
  .pulseCircle__sap {
    bottom: 312px;
    left: 59.8%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__sap {
        bottom: 363px; } }
    @media (min-width: 1681px) {
      .pulseCircle__sap {
        bottom: 417px; } }
  .pulseCircle__vak {
    bottom: 363px;
    left: 60.1%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__vak {
        bottom: 428px; } }
    @media (min-width: 1681px) {
      .pulseCircle__vak {
        bottom: 489px; } }
  .pulseCircle__jus {
    bottom: 404px;
    left: 60.8%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__jus {
        bottom: 474px;
        left: 61.1%; } }
    @media (min-width: 1681px) {
      .pulseCircle__jus {
        bottom: 541px;
        left: 61.1%; } }
  .pulseCircle__itu {
    bottom: 356px;
    left: 68.7%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__itu {
        bottom: 414px; } }
    @media (min-width: 1681px) {
      .pulseCircle__itu {
        left: 68.9%;
        bottom: 474px; } }
  .pulseCircle__kun {
    bottom: 339px;
    left: 66.5%; }
    @media (min-width: 1441px) and (max-width: 1680px) {
      .pulseCircle__kun {
        bottom: 396px; } }
    @media (min-width: 1681px) {
      .pulseCircle__kun {
        left: 66.7%;
        bottom: 454px; } }

/**
* @subsection animations
* Настройки кейфреймов анимаций
*/
@keyframes pulse {
  0% {
    width: 5px;
    height: 5px;
    border: 1px solid white; }
  90% {
    width: 25px;
    height: 25px;
    border: 1px solid white; }
  100% {
    width: 25px;
    height: 25px;
    border: 1px solid white; } }

@keyframes boxPulseKor {
  0% {
    fill: rgba(44, 175, 236, 0.8); }
  40% {
    fill: url(#gradKorea);
    fill-opacity: .8; }
  70% {
    fill: url(#gradKorea);
    fill-opacity: .8; }
  100% {
    fill: rgba(44, 175, 236, 0.8); } }

@keyframes boxPulseJap {
  0% {
    fill: rgba(44, 175, 236, 0.8); }
  40% {
    fill: url(#jg1);
    fill-opacity: .8; }
  70% {
    fill: url(#jg1);
    fill-opacity: .8; }
  100% {
    fill: rgba(44, 175, 236, 0.8); } }

@keyframes boxPulseSah {
  0% {
    fill: rgba(44, 175, 236, 0.8); }
  40% {
    fill: url(#sg1);
    fill-opacity: .8; }
  70% {
    fill: url(#sg1);
    fill-opacity: .8; }
  100% {
    fill: rgba(44, 175, 236, 0.8); } }

@keyframes heightLine100 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 100px; } }

@keyframes heightLine35 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 35px; } }

@keyframes heightLine50 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 50px; } }

@keyframes heightLine70 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 70px; } }

@keyframes heightLine63 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 63px; } }

@keyframes heightLine58 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 58px; } }

@keyframes heightLine90 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 90px; } }

@keyframes heightLine130 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 130px; } }

@keyframes heightLine60 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 60px; } }

@keyframes heightLine95 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 95px; } }

@keyframes heightLine155 {
  0% {
    height: 0; }
  65% {
    height: 0; }
  100% {
    height: 155px; } }

@keyframes textAni {
  0% {
    opacity: 0; }
  65% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes citys {
  0% {
    opacity: 0; }
  65% {
    opacity: 0; }
  70% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.sectionTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto";
  font-size: 60px;
  color: white;
  text-align: center; }

.japan {
  position: relative;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background: #be5959;
  overflow: hidden; }

.korea {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #cac858; }

.sakhalin {
  position: relative;
  width: 100%;
  height: 50vh;
  background: #935eb6; }
