/**
* @section base
* Базовые настройки страницы
*/
body,
html {
    margin: 0;
    height: 100%;
}

body {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42;
    -webkit-font-smoothing: antialaised;
}

.wrapper {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}